import React, { useEffect } from "react";
import Carousel from "../../components/Carousel/Carousel";
import styles from "./hompage.module.css";
import Chilli from "../../assets/product-imgs/chilli.png";
// import Makhana from "../../assets/product-imgs/Makhana.png";
import DryFruits from "../../assets/product-imgs/dry-fruits.png";
import Pulses from "../../assets/product-imgs/pulses.png";
import Cereals from "../../assets/product-imgs/cereals.png";
import whyUsImg from "../../assets/whyUsImg.png";
import img1 from "../../assets/carousel-img/1.jpg";
import img2 from "../../assets/carousel-img/2.jpg";
import img3 from "../../assets/carousel-img/3.jpg";
import CarouselSmall from "../../components/CarouselSmall/CarouselSmall";
// import bg from "../../assets/whyUSbg.jpg";
function HomePage() {
  // const types = ["all", "spices", "dryFruits", "cereals", "pulses", "makhana"];
  // const [cat, setCat] = useState("all");
  // const images1 = [img1, img2, img3];
  const images1 = [img1, img2, img3];
  const names = ["Spices", "Makhana", "Dry Fruits", "Pulses", "Cereals"];
  const images2 = [
    {
      type: "spices",
      pic: Chilli,
    },
    {
      type: "dry-fruits",
      pic: DryFruits,
    },
    {
      type: "pulses",
      pic: Pulses,
    },
    {
      type: "cereals",
      pic: Cereals,
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: "0" });
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.slider}>
          <Carousel text={true} images={images1} />
        </div>

        <div style={{ marginTop: "50px" }} className={styles.slider}>
          <div className={styles.prodRange}>
            <strong>Our Product Range</strong>
          </div>
          <CarouselSmall names={names} images={images2} />
        </div>

        <div className={styles.whyUsCaption}>Why Choose Us?</div>
        <div className={styles.whyUs}>
          <div className={styles.left}>
            <img src={whyUsImg} alt="why us" />
          </div>
          <div className={styles.right}>
            {/* <div className={styles.rightCap}>FEATURES OF FARMROOTS</div> */}
            <div className={styles.info}>
              With the increasing demand for safe and sustainable products, we
              are committed to supporting local farmers and communities and
              delivering quality products to our customers.
            </div>
            <div className={styles.points}>
              <ul>
                <li>High Quality Varieties</li>
                <li>Safer Agronomic Practices</li>
                <li>Farmer centric approach</li>
                <li>Ensuring Safe Processing & Storage</li>
                <li>Meet Market demand and consumer preferences</li>
                <li>Utilization of native diverse genetic resources</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
